<template>
  <div class="container-fluid">
    <div class="banner">
      <h1 data-aos="fade-down">Our Projects</h1>
    </div>
    <div class="container py-5">
      <div class="row">
        <div class="col-lg-4 col-sm-12"  data-aos="fade-up"  data-aos-delay="100">
          <ProjectList @project="project" />
        </div>
        <div class="col-lg-8 col-sm-12"  data-aos="fade-down"  data-aos-delay="300">
          <div class="container">
            <h2 class="my-3">{{ activeProject.project }}</h2>
            <p>
              {{ activeProject.details }}
            </p>
            <ul class="list-group my-4">
              <li class="list-group-item">
                Client : {{ activeProject.Client }}
              </li>
              <li class="list-group-item">
                Year of Completion : {{ activeProject.Completion }}
              </li>
            </ul>
            <div class="row">
              <div
                class="col-lg-6 col-sm-12"
                v-for="(image, key) in activeProject.images"
                :key="key"
              >
                <div class="imageCard">
                  <img :src="image" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import projects from "@/assets/data/projects.json";
import ProjectList from "@/components/ProjectList.vue";

export default {
  data() {
    return {
      projectUpdated: false,
      activeProject: null,
    };
  },
  components: { ProjectList },
  methods: {
    project(id = projects.length) {
      const project = projects.filter((project) => project.id == id)[0];
      this.activeProject = project;
    },
  },
  created() {
    this.project();
  },
};
</script>

<style lang="scss" scoped>
.container-fluid {
  padding: 0;
  .banner {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 60vh;
    background: linear-gradient(
        to left,
        rgba(0, 0, 0, 0.5),
        rgba(0, 0, 0, 0.4)
      ),
      url("@/assets/img/projects/banner.png");
    background-position: center center;
    background-size: cover;
    h1 {
      font-size: 60px;
      color: #fff;
    }
    @media screen and (max-width: 425px) {
      h1 {
        font-size: 30px;
        color: #fff;
      }
    }
  }

  .imageCard {
    max-width: 100%;
    height: 400px;
    position: relative;
    display: flex;
    justify-content: center;
    z-index: 1;
    cursor: pointer;
    margin: 5px 0;
    transition: 0.5s ease-in-out;
    overflow: hidden;

    img {
      left: 0;
      object-fit: cover;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    &:hover {
      img {
        transition: all 0.5s ease-in-out;
        transform: scale(1.2);
      }
    }
  }
  h2 {
    color: var(--color-primary);
    font-weight: bold;
  }
  p {
    margin: 2rem 0;
    color: var(--footer-color-secondary);
  }
}
</style>

<template>
  <div class="card shadow-sm col-lg-12 my-3">
    <div class="card-body">
      <h2>{{ heading }}</h2>
      <span>
       {{ details }}
      </span>
      <div class="hr"></div>
    </div>
  </div>
</template>

<script>
import { reactive } from "vue";

export default {
  setup() {
    const state = reactive({
      count: 0,
    });
    return {
      state,
    };
  },
  props:["heading","details"]
};
</script>

<style lang="scss" scoped>
.card {
  text-align: left;
  border: 0;
  padding: 2rem;
  line-height: 1rem;
  margin:2rem 0;
  h2 {
    color: var(--color-primary);
    font-weight: 700;
  }
  span {
    color: var(--footer-color-secondary);
    text-transform: capitalize;
    font-size: 15px;
    line-height: 1.5rem;
  }
  .hr {
    margin-top: 30px;
    width: 100%;
    height: 1.5px;
    background-color: var(--color-secondary);
  }
}
</style>

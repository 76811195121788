<template>
  <header>
    <router-link to="/">
      <img
        src="@/assets/img/logo.png"
        alt="Logo"
        srcset="@/assets/img/logo.png"
        class="brand"
    /></router-link>

    <div class="headerWrapper">
      <div class="item">
        <div class="itemSide">
          <i class="bx bxs-phone-call"></i>
        </div>
        <div class="side">
          <span class="header">call us</span>
          <span>077-7707-689</span>
        </div>
      </div>
      <div class="item">
        <div class="itemSide">
          <i class="bx bx-envelope"></i>
        </div>
        <div class="side">
          <span class="header">Email us</span>
          <span>arsconstructionspvtltd@gmail.com </span>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  setup() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
header {
  width: 100vw;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  padding: 0 1rem;
  .brand {
    width: 175px;
  }
  .headerWrapper {
    display: flex;
    align-items: center;
    .item {
      display: flex;
      align-items: center;
      .itemSide {
        height: 100%;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          color: var(--color-secondary);
          font-weight: 500;
          font-size: 20px;
        }
      }
      .side {
        margin: 0 5px;
        display: flex;
        flex-direction: column;
        span {
          color: var(--footer-color-secondary);
          font-weight: 600;

          &.header {
            font-size: 13px;
            font-weight: 100;
            text-transform: capitalize;
          }
        }
      }
    }
  }
  @media screen and (max-width: 768px) {
    .brand {
      width: 150px;
    }
    .headerWrapper {
      .item {
        .itemSide {
          height: 100%;
          width: 15px;

          i {
            font-size: 16px;
          }
        }
        .side {
          margin: 0 5px;
          display: flex;
          flex-direction: column;
          span {
            color: var(--footer-color-secondary);
            font-weight: 500;
            font-size: 9px;

            &.header {
              font-size: 11px;
              font-weight: 100;
              text-transform: capitalize;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 555px) {
    .brand {
      width: 130px;
    }
    .headerWrapper {
      .item {
        width: 100%;
        display: flex;
        padding: 0;
        margin: 0;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .itemSide {
          display: none;
          i {
          }
        }
        .side {
          display: flex;
          flex-direction: column;
          span {
            font-size: 8px;
            &.header {
              font-size: 9px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 425px) {
    .brand {
      width: 120px;
    }
    .headerWrapper {
      flex-direction: column;
      .item {
        width: 100%;
        display: flex;
        padding: 0;
        margin:5px 0;
        flex-direction: row;
        align-items: center;
        justify-content: center;

        .side {
          display: flex;
          flex-direction: column;
          span {
            font-size: 8px;
            &.header {
              font-size: 9px;
              text-align: center;
            }
          }
        }
      }
    }
  }
}
</style>

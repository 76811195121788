import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap";
import HeaderPart from "@/components/HeaderPart";
import FooterPart from "@/components/FooterPart";
import NavBar from "@/components/NavBar";
import "boxicons/css/boxicons.min.css";
import "aos/dist/aos.css";
import VueLazyLoad from "vue3-lazyload";
const app = createApp(App);

app.component("HeaderPart", HeaderPart);
app.component("FooterPart", FooterPart);
app.component("NavBar", NavBar);
app.use(store);
app.use(router);
app.mount("#app");
app.use(VueLazyLoad, {
  loading: "",
  error: "",
});
